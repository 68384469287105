<template>
  <div class="container mx-auto px-4 lg:px-5 xl:px-0">
    <div class="flex items-center justify-center min-h-screen h-screen">
      <Card class="w-full md:w-3/4 lg:max-w-sm">
        <template #title>
          <GuestLogo />
        </template>

        <template #content>
          <slot />
        </template>
      </Card>
    </div>

    <Toast />
  </div>
</template>

<script lang="ts" setup>
const title = "RhinoCalls"

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${title}` : title
  }
})
</script>
